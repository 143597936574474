import { createSelector } from "reselect";

import COUNTRIES from "~/constants/enums/countries";
import CURRENCIES from "~/constants/enums/currencies";

export const getData = state => state.app.referenceData;

export const getCountries = createSelector(getData, ({ countries }) =>
  countries.sort((a, b) => {
    if (a.countryKey === COUNTRIES.GB) return -1;
    if (b.countryKey === COUNTRIES.GB) return 1;
    if (a.countryKey === COUNTRIES.IE) return -1;
    if (b.countryKey === COUNTRIES.IE) return 1;

    return a.countryName > b.countryName ? 1 : -1;
  })
);

export const getCurrencies = createSelector(getData, ({ currencies = [] }) =>
  currencies.sort((a, b) => {
    if (a.currencyCode === CURRENCIES.GBP) return -1;
    if (b.currencyCode === CURRENCIES.GBP) return 1;
    if (a.currencyCode === CURRENCIES.EUR) return -1;
    if (b.currencyCode === CURRENCIES.EUR) return 1;

    return a.currencyCode > b.currencyCode ? 1 : -1;
  })
);
