import _ from "lodash";
import PropTypes from "prop-types";
import { propTypes } from "redux-form";

import { Card, CommonStep } from "@dpdgroupuk/mydpd-ui";

import {
  EDIT_PRODUCT,
  PRODUCT_DETAILS,
  PRODUCT_INFO,
} from "~/constants/strings";
import info from "~/models/info";
import ProductForm from "~/pages/ProductBook/components/ProductCard/ProductForm";
import ReadOnlyHeader from "~/pages/ProductBook/components/ReadOnlyHeader";

import styles from "./EditCard.module.scss";

const ProductCard = ({
  title,
  isReadonly,
  isDisabled,
  children,
  helpModalTitle,
  closeButtonText,
  confirmButtonText,
  confirmButtonVariant,
  closeButtonVariant,
  onClickHelpIcon,
  ...props
}) => {
  const readOnlyHeader = (
    <span className={styles.title}>
      <span>{PRODUCT_DETAILS}</span>
      <ReadOnlyHeader />
    </span>
  );

  return (
    <CommonStep
      classes={{
        container: styles.container,
      }}
      step={2}
      title={isReadonly ? readOnlyHeader : title}
      helpModalTitle={title}
      header={
        <>
          {_.isArray(info[PRODUCT_INFO]) && (
            <Card.HelpIcon
              help={info[PRODUCT_INFO]}
              helpModalTitle={title}
              closeButtonText={closeButtonText}
              confirmButtonText={confirmButtonText}
              confirmButtonVariant={confirmButtonVariant}
              closeButtonVariant={closeButtonVariant}
              onClickHelpIcon={onClickHelpIcon}
            />
          )}
        </>
      }
    >
      <ProductForm isReadonly={isReadonly} isDisabled={isDisabled} {...props} />
      {children}
    </CommonStep>
  );
};

ProductCard.defaultProps = {
  title: EDIT_PRODUCT,
  isDisabled: true,
};

ProductCard.propTypes = {
  title: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  children: PropTypes.node,
  typeCheckboxLabel: PropTypes.string,
  closeButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVariant: PropTypes.string,
  closeButtonVariant: PropTypes.string,
  onClickHelpIcon: PropTypes.func,
  ...propTypes,
};

export default ProductCard;
