import { useMemo } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Field, propTypes } from "redux-form";

import CommodityCodeFinder from "@dpdgroupuk/mydpd-commodity-finder";
import { Form as MyDpdUiForm } from "@dpdgroupuk/mydpd-ui";

import { ProductBookEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { floatNormalize } from "~/models/normalizers";
import { formatMessage } from "~/utils/string";

import CountriesAutocomplete from "../CountriesAutocomplete/CountriesAutocomplete";
import styles from "./ProductForm.module.scss";

const ProductForm = props => {
  const {
    onFieldEntry,
    countries,
    isReadonly,
    isDisabled,
    requiredFields,
    selectedCountry,
    onCountryChange,
    onCommoditySearch,
    onCommodityCodeSearch,
    change,
    currencies,
    selectedCurrency,
    onFindClick,
  } = props;

  const unitValueLabel = useMemo(
    () => formatMessage(S.PRODUCT_UNIT_VALUE_CURRENCY, selectedCurrency),
    [selectedCurrency]
  );

  return (
    <MyDpdUiForm autocomplete="off" className={classNames(styles.container)}>
      <Container fluid className="p-0">
        <Row>
          <Col className={styles.column}>
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.SHORT_NAME}
              name={ProductBookEntity.SHORT_NAME}
              maxLength={45}
              helperText={S.ENTER_SHORT_NAME}
              onBlur={onFieldEntry}
              required={requiredFields[ProductBookEntity.SHORT_NAME]}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={CountriesAutocomplete}
              id={ProductBookEntity.COUNTRY_OF_ORIGIN}
              name={ProductBookEntity.COUNTRY_OF_ORIGIN}
              label={S.COUNTRY_OF_ORIGIN}
              helperText={S.ENTER_OR_SELECT_DESTINATION_COUNTRY}
              onCountryChange={onCountryChange}
              countries={countries}
              onBlur={onFieldEntry}
              selectedCountry={selectedCountry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.WEBSITE_ADDRESS}
              name={ProductBookEntity.PRODUCT_URL}
              maxLength={255}
              helperText={S.ENTER_PRODUCT_URL}
              onBlur={onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.PRODUCT_CODE}
              name={ProductBookEntity.PRODUCT_CODE}
              helperText={S.ENTER_PRODUCT_CODE_IDENTIFIER}
              onBlur={onFieldEntry}
              maxLength={255}
            />
            <div className="d-flex align-items-center">
              <Field
                readonly={isReadonly}
                disabled={isDisabled}
                component={MyDpdUiForm.MaterialTextInput}
                label={S.COMMODITY_CODE}
                name={ProductBookEntity.COMMODITY_CODE}
                helperText={S.CLARIFY_YOUR_GOODS}
                onBlur={onFieldEntry}
                maxLength={8}
              />
              <CommodityCodeFinder
                buttonClassName={styles.findCommodityButton}
                disabled={isDisabled || isReadonly}
                onSearch={onCommoditySearch}
                onCommodityCodeSearch={onCommodityCodeSearch}
                onFindClick={onFindClick}
                onSubmit={value =>
                  change(ProductBookEntity.COMMODITY_CODE, value)
                }
              />
            </div>
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.PRODUCT_TYPE}
              name={ProductBookEntity.PRODUCT_TYPE}
              helperText={S.PRODUCT_TYPE_EXAMPLE}
              maxLength={40}
              onBlur={onFieldEntry}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.PRODUCT_COMPOSITION}
              name={ProductBookEntity.PRODUCT_COMPOSITION}
              helperText={S.PRODUCT_COMPOSITION_EXAMPLE}
              onBlur={onFieldEntry}
              maxLength={200}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.DETAILED_PRODUCT_DESCRIPTION}
              name={ProductBookEntity.PRODUCT_DESCRIPTION}
              helperText={S.DETAILED_PRODUCT_DESCRIPTION_EXAMPLE}
              onBlur={onFieldEntry}
              maxLength={200}
            />
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={S.PRODUCT_UNIT_WEIGHT}
              name={ProductBookEntity.UNIT_WEIGHT}
              helperText={S.MAXIMUM_TOTAL_WEIGHT_PER_PACKAGE}
              onBlur={onFieldEntry}
              maxLength={8}
              normalize={floatNormalize}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              name={ProductBookEntity.CURRENCY}
              component={MyDpdUiForm.MaterialDropdown}
              label={S.CURRENCY}
              helperText={S.SELECT_PRODUCT_CURRENCY}
              values={currencies}
              onBlur={onFieldEntry}
              textTransform={S.UPPERCASE}
            />
          </Col>
          <Col lg={6}>
            <Field
              readonly={isReadonly}
              disabled={isDisabled}
              component={MyDpdUiForm.MaterialTextInput}
              label={unitValueLabel}
              name={ProductBookEntity.UNIT_VALUE}
              helperText={S.PRODUCT_UNIT_VALUE_EXAMPLE}
              onBlur={onFieldEntry}
              maxLength={8}
              normalize={floatNormalize}
            />
          </Col>
        </Row>
      </Container>
    </MyDpdUiForm>
  );
};

ProductForm.propTypes = {
  onFieldEntry: PropTypes.object,
  countries: PropTypes.bool,
  isReadonly: PropTypes.bool,
  isDisabled: PropTypes.object,
  requiredFields: PropTypes.object,
  selectedCountry: PropTypes.func,
  onCountryChange: PropTypes.func,
  onCommoditySearch: PropTypes.func,
  onCommodityCodeSearch: PropTypes.func,
  change: PropTypes.object,
  currencies: PropTypes.func,
  onFindClick: PropTypes.func,
  ...propTypes,
};

export default ProductForm;
