import api from "./api";

export const fetchCommodities = (query, options) =>
  api.get({
    path: "/reference/commodities",
    query,
    options,
  });

export const fetchCommoditiesByCode = (query, options) =>
  api.get({
    path: "/reference/commodities/code",
    query,
    options,
  });

export const fetchCurrencies = (query, options) =>
  api.get({
    path: "/reference/currencies",
    query,
    options,
  });
