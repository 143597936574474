import { ORM } from "redux-orm";

import { ProductBook } from "./Product/models";

const orm = new ORM({
  stateSelector: state => state.orm,
});

orm.register(ProductBook);

export default orm;
