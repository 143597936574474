import { ProductBookImportFields } from "~/constants/forms";
import { FILE_MUST_BE_SELECTED } from "~/constants/strings";

export const importProduct = values => {
  const errors = {};

  if (!values[ProductBookImportFields.PRODUCT_BOOK_FILE]) {
    errors.filename = FILE_MUST_BE_SELECTED;
    errors[ProductBookImportFields.PRODUCT_BOOK_FILE] = FILE_MUST_BE_SELECTED;
  }
  return errors;
};
