import Joi from "joi";

import { ProductBookEntity } from "~/constants/forms";
import {
  commodityCode,
  countryOfOrigin,
  detailedProductDescription,
  productCode,
  productComposition,
  productType,
  productUnitValue,
  productUrl,
  productWeight,
  shortName,
} from "~/models/validators/commonSchema";

export default Joi.object({
  [ProductBookEntity.SHORT_NAME]: shortName.required(),
  [ProductBookEntity.COUNTRY_OF_ORIGIN]: countryOfOrigin,
  [ProductBookEntity.PRODUCT_URL]: productUrl,
  [ProductBookEntity.PRODUCT_CODE]: productCode,
  [ProductBookEntity.COMMODITY_CODE]: commodityCode,
  [ProductBookEntity.PRODUCT_TYPE]: productType,
  [ProductBookEntity.PRODUCT_COMPOSITION]: productComposition,
  [ProductBookEntity.PRODUCT_DESCRIPTION]: detailedProductDescription,
  [ProductBookEntity.UNIT_WEIGHT]: productWeight,
  [ProductBookEntity.UNIT_VALUE]: productUnitValue,
});
