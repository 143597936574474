export const BOOK_SEARCH_FORM = "BOOK_SEARCH_FORM";
export const PRODUCT_SEARCH_FORM = "PRODUCT_SEARCH_FORM";
export const PRODUCT_IMPORT_FORM = "PRODUCT_IMPORT_FORM";
export const PRODUCT_FORM = "PRODUCT_FORM";
export const SEARCH_AND_SELECT_HELP = "SEARCH_AND_SELECT_HELP";

export const Fields = {
  SHORT_NAME: "shortName",
  TYPE: "type",
  COUNTRY_OF_ORIGIN: "countryOfOrigin",
  PRODUCT_CODE: "productCode",
  COMMODITY_CODE: "commodityCode",
  PRODUCT_TYPE: "productType",
  PRODUCT_COMPOSITION: "productComposition",
  PRODUCT_DESCRIPTION: "productDescription",
  UNIT_WEIGHT: "unitWeight",
  CURRENCY: "currency",
  PRODUCT_URL: "productUrl",
  UNIT_VALUE: "unitValue",
};

export const ProductBookFilterOptionsList = [
  {
    value: Fields.SHORT_NAME,
    label: "Short Name",
  },
  {
    value: Fields.PRODUCT_DESCRIPTION,
    label: "Product Description",
  },
  {
    value: Fields.COUNTRY_OF_ORIGIN,
    label: "Country",
  },
  {
    value: Fields.PRODUCT_CODE,
    label: "Product Code",
  },
  {
    value: Fields.PRODUCT_TYPE,
    label: "Product Type",
  },
  {
    value: Fields.PRODUCT_COMPOSITION,
    label: "Product Composition",
  },
  {
    value: Fields.COMMODITY_CODE,
    label: "Commodity Code",
  },
  {
    value: Fields.CURRENCY,
    label: "Currency",
  },
];

export const SEARCH_CRITERIA_FIELD = "searchCriteriaField";
export const SEARCH_CRITERIA_VALUE = "searchCriteriaValue";
export const ADDRESS_TYPE = "addressType";
const PRODUCT_BOOK_FILE = "productBookFile";
const IMPORT_OPTIONS = "updateType";

export const FilterFields = {
  ADDRESS_TYPE,
  SEARCH_CRITERIA_FIELD,
  SEARCH_CRITERIA_VALUE,
  [Fields.TYPE]: Fields.TYPE,
};

export const ProductBookImportFields = {
  PRODUCT_BOOK_FILE,
  IMPORT_OPTIONS,
};

export const ImportOptionsValue = {
  IMPORT_NEW_ONLY: "0",
  OVERWRITE: "1",
  EMPTY: "2",
};

export const ProductBookEntity = {
  SHORT_NAME: Fields.SHORT_NAME,
  PRODUCT_CODE: Fields.PRODUCT_CODE,
  COMMODITY_CODE: Fields.COMMODITY_CODE,
  UNIT_VALUE: Fields.UNIT_VALUE,
  COUNTRY_OF_ORIGIN: Fields.COUNTRY_OF_ORIGIN,
  PRODUCT_URL: Fields.PRODUCT_URL,
  PRODUCT_TYPE: Fields.PRODUCT_TYPE,
  PRODUCT_COMPOSITION: Fields.PRODUCT_COMPOSITION,
  PRODUCT_DESCRIPTION: Fields.PRODUCT_DESCRIPTION,
  UNIT_WEIGHT: Fields.UNIT_WEIGHT,
  CURRENCY: Fields.CURRENCY,
};
