import { get } from "lodash";

import { createPropsSelectorOrm, createSelectorOrm } from "../utils";

export const getProductBooks = createSelectorOrm(session =>
  session.ProductBook.all().toRefArray()
);

export const getProductBook = createPropsSelectorOrm((session, addressBookId) =>
  get(session.ProductBook.withId(addressBookId), "ref")
);
