import { get, isPlainObject, merge, omit, omitBy, reduce } from "lodash";

import COUNTRIES from "~/constants/enums/countries";
import CURRENCIES from "~/constants/enums/currencies";
import { Fields, FilterFields } from "~/constants/forms";
import { getQueryFilters, getQueryPagination } from "~/utils/query";

export const getSearchCriteria = (searchCriteriaField, searchCriteriaValue) => {
  let field = searchCriteriaField;
  const value = (searchCriteriaValue || "").toString().trim();

  if (value && !field) {
    field = Fields.SHORT_NAME;
  }

  return field && value
    ? {
        [FilterFields.SEARCH_CRITERIA_FIELD]: field,
        [FilterFields.SEARCH_CRITERIA_VALUE]: value.toUpperCase(),
      }
    : null;
};

export const trimEntityFields = entity => {
  const value = omit(
    omitBy(
      entity,
      value => value === null || value === undefined || value === ""
    ),
    ["productBookId", "readOnly"]
  );

  return reduce(
    value,
    (acc, value, key) => {
      if (typeof value === "string") {
        acc[key] = value.trim();
      } else if (value && isPlainObject(value)) {
        acc[key] = trimEntityFields(value);
      } else {
        acc[key] = value;
      }

      return acc;
    },
    {}
  );
};

export const getSearchQuery = location => {
  const { searchCriteriaField, searchCriteriaValue, ...query } =
    getQueryFilters(location);
  const page = getQueryPagination(location);

  let queryRequest = {
    ...page,
    ...query,
  };

  const searchCriteria = getSearchCriteria(
    searchCriteriaField,
    searchCriteriaValue
  );

  if (searchCriteria) {
    queryRequest = {
      ...queryRequest,
      ...searchCriteria,
    };
  }

  return queryRequest;
};

export const mapCountriesToKeyValue = countries =>
  countries.map(({ countryKey, countryName, euCountry }) => ({
    label: countryName,
    value: countryKey,
    euCountry,
  }));

export const mapCurrenciesToKeyValue = currencies =>
  currencies.map(({ currencyCode }) => ({
    label: currencyCode,
    value: currencyCode,
  }));

export const getCountryByCountryCode = (countryCode, countries) =>
  countries.find(({ value }) => value === countryCode);

export const updateCurrency = (data, currencies) => {
  const { currency, ...productBook } = data || {};

  if (!currencies.find(({ value }) => value === currency)) {
    return {
      ...productBook,
      currency: CURRENCIES.GBP,
    };
  }

  return data;
};

export const updateCountry = (data, countries) => {
  const { countryOfOrigin, ...productBook } = data || {};

  if (!countries.find(({ value }) => value === countryOfOrigin)) {
    return {
      ...productBook,
      countryOfOrigin: COUNTRIES.GB,
    };
  }

  return data;
};

export const getInitialProductState = (
  initialValues,
  queryStringValues,
  currencies,
  countries
) => {
  let updatedProductBook = updateCurrency(initialValues, currencies);
  updatedProductBook = updateCountry(updatedProductBook, countries);

  try {
    if (queryStringValues) {
      const source = JSON.parse(decodeURIComponent(queryStringValues));
      return merge(updatedProductBook, source);
    }
  } catch (err) {
    // ignore error here
  }

  return updatedProductBook;
};

export const isProductBookReadonly = (record, preferences) => {
  if (!record) return false;

  if (
    get(record, "readOnly") ||
    !!get(
      preferences,
      "advancedCustomerConfiguration.readOnlyProductBook",
      false
    )
  ) {
    return true;
  }
};
