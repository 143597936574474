import { connect } from "react-redux";
import { compose } from "recompose";

import { createCancelableRequest } from "@dpdgroupuk/fetch-client";

import {
  fetchCommodities,
  fetchCommoditiesByCode,
} from "~/redux/reference/actions";

export default compose(
  connect(null, dispatch => ({
    onCommoditySearch: createCancelableRequest(async (query, options) =>
      dispatch(fetchCommodities(query, options))
    ),
    onCommodityCodeSearch: createCancelableRequest(async (query, options) =>
      dispatch(fetchCommoditiesByCode(query, options))
    ),
  }))
);
