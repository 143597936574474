import PropTypes from "prop-types";
import { compose } from "recompose";
import { propTypes } from "redux-form";

import { Button, Main } from "@dpdgroupuk/mydpd-ui";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { PRODUCT_BOOK_ENTRY_PAGE } from "~/constants/analytics";
import * as S from "~/constants/strings";

const UpdatesAndSave = ({ isDisabled, isReadonly, onSubmit, ...props }) => (
  <Main.Footer className="dark main-footer">
    <Button.Toolbar className="w-100">
      <Button
        disabled={isDisabled || isReadonly}
        variant="light"
        onClick={props.handleSubmit(onSubmit)}
      >
        {S.SAVE}
      </Button>
    </Button.Toolbar>
  </Main.Footer>
);

UpdatesAndSave.propTypes = {
  onSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  analyticsMetaDate: PropTypes.any,
  ...propTypes,
};

export default compose(
  withTrackProps({
    onSubmit: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_SAVE,
  })
)(UpdatesAndSave);
