import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./RadioInput.module.scss";

const RadioInput = ({ label, classNameLabel, value, input, ...inputProps }) => (
  <label className={classNames(styles.label, classNameLabel)} htmlFor={value}>
    <input type="radio" value={value} {...input} {...inputProps} />
    {label}
  </label>
);

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  value: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
};

export default RadioInput;
