const createPageMeta = (interfaceId, rest = {}) => ({
  INTERFACE_ID: interfaceId,
  ...rest,
});

export const PRODUCT_BOOK_ENTRY_PAGE = createPageMeta("p9kRyQGGAqCIaZ7r", {
  // List view
  LOAD: "QFhtkQGGAqCIaQys",
  SELECT_ROW: "ZX0jUQGGAqCIaS7p",
  ENTER_TEXT_SEARCH: "S_0jUQGGAqCIaS8g",
  ON_CLICK_IMPORT: "V6clTQGGAqCIaY25",
  ON_CLICK_FIRST: "6uuUWQGGAqCIaaMq",
  ON_CLICK_LAST: "nPBMWQGGAqCIaaic",
  ON_CLICK_NEW: "nVFXGQGGAqCIad4j",
  ON_CLICK_NEXT: "wXM8WQGGAqCIaZ5i",
  ON_CLICK_PREV: "eS58WQGGAqCIaaHT",
  ON_CLICK_DELETE_ALL: "TP0jUQGGAqCIaS7_",
  ON_CLICK_DELETE: "a11LyQGGAqCIaYWR",
  ON_CLICK_SAVE: "fXyxmQGGAqCIaa_U",
  ON_CLICK_COMMODITY_FIND: "o0qYqIGGAqCIaMxL",
  ON_CLICK_PRODUCT_DETAILS_HELP: "MSAFqIGGAqCIaAQW",
  ON_CLICK_COLUMN_HEADER: "cZ0jUQGGAqCIaS3V",
  ON_SELECT_SEARCH_CRITERIA: "3Tk8KQGGAqCIaZY8",
  ON_SELECT_COLUMN_HEADER_ITEM: "._0jUQGGAqCIaS8j",
  // Edit view
  ON_BLUR_SHORT_NAME: "og1RmQGGAqCIab..",
  ON_BLUR_PRODUCT_CODE: "98XHLgGGAqCIab45",
  ON_BLUR_COMMODITY_CODE: "wSXHLgGGAqCIab5H",
  ON_BLUR_UNIT_VALUE: "psXHLgGGAqCIab4s",
  ON_SELECT_COUNTRY: "OEXHLgGGAqCIab4E",
  ON_BLUR_PRODUCT_URL: "NJXHLgGGAqCIab8A",
  ON_BLUR_PRODUCT_TYPE: "10XHLgGGAqCIab4f",
  ON_BLUR_PRODUCT_COMPOSITION: "pcXHLgGGAqCIab4y",
  ON_BLUR_PRODUCT_DESCRIPTION: "HCXHLgGGAqCIab5A",
  ON_BLUR_UNIT_WEIGHT: "jyXHLgGGAqCIab5g",
  ON_SELECT_CURRENCY: "tUGfDoGGAqCIZN3l",
});

export const IMPORT_PRODUCT_BOOK_MODAL = createPageMeta("ZrN3TQGGAqCIaX_P", {
  LOAD: "BYNlTQGGAqCIaZEd",
  CLICK_CLOSE: "uINlTQGGAqCIaZEO",
  CLICK_SELECT_FILE: "If1lTQGGAqCIaZDL",
  CLICK_UPLOAD: "C_1lTQGGAqCIaZDS",
  SELECT_EMPTY_PRODUCT: "P71lTQGGAqCIaZCk",
  SELECT_IMPORT_NEW_ONLY: "Lz1lTQGGAqCIaZCK",
  SELECT_OVERWRITE_PRODUCT: "Yb1lTQGGAqCIaZCW",
});

export const MENU = {
  LOAD: "nb_PSXaD.AAAAdCJ",
  CLICK_ADDRESS_BOOK: "JpWuyXaD.AAAARFD",
  CLICK_COLLECTIONS: "2tiOyXaD.AAAAeTY",
  CLICK_DPD_LOGO: "3WQ5yXaD.AAAATDC",
  CLICK_DELIVERIES: "Op9OyXaD.AAAAeo4",
  CLICK_DEPOT_FINDER: "z1euyXaD.AAAARFo",
  CLICK_HELP: "n9FuyXaD.AAAARGk",
  CLICK_LOG_OFF: "h8tuyXaD.AAAARG6",
  CLICK_MYDPD: "ISlmyXaD.AAAAd2H",
  CLICK_SHIPPING: "UKB2yXaD.AAAAeF3",
  CLICK_SHOP_RETURNS: "vmquyXaD.AAAAREm",
};

export const COLLECTIONS_MENU = {
  CLICK_SEARCH: "yYWVyXaD.AAAAToT",
  CLICK_DASHBOARD: "Vy5zyXaD.AAAAUK3",
  CLICK_WATCH_LIST: "zQ0eTXaD.AAAASg0",
  CLICK_CREATE_COLLECTION: "EWdzyXaD.AAAAUMW",
};

export const DELETE_CONFIRMATION_POP_UP = createPageMeta("AnItGQGGAqCIadGT", {
  PREV_INTERFACE_ID: PRODUCT_BOOK_ENTRY_PAGE.INTERFACE_ID,
  ON_CLICK_NO: "VOSbGQGGAqCIaRgR",
  ON_CLICK_YES: "McJtGQGGAqCIadSx",
  LOAD: "dkAMmQGGAqCIaVIo",
});

export const DELETE_ALL_CONFIRMATION_POP_UP = createPageMeta(
  "WmYAmQGGAqCIaTSK",
  {
    PREV_INTERFACE_ID: PRODUCT_BOOK_ENTRY_PAGE.INTERFACE_ID,
    ON_CLICK_NO: "zM2AmQGGAqCIaTTu",
    ON_CLICK_YES: "nY2AmQGGAqCIaTSs",
    LOAD: "MLJEmQGGAqCIaV7O",
  }
);

export const IMPORT_RESULT_POP_UP = createPageMeta("VkvWzQGGAqCIaWai", {
  LOAD: "97J3TQGGAqCIaX60",
  CLICK_CLOSE: "m9J3TQGGAqCIaX57",
  ON_CLICK_YES: "iLJ3TQGGAqCIaX6S",
  CLICK_SORT_ICON: "_DJ3TQGGAqCIaX6F",
});
