import Joi from "joi";

import { joiExtension } from "@dpdgroupuk/mydpd-express-helpers/utils";

import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

export const shortName = Joi.string().max(45).label(S.SHORT_NAME);

export const countryOfOrigin = Joi.string().label(S.COUNTRY_OF_ORIGIN);

export const countryCode = Joi.string().max(2).label(S.COUNTRY_OF_ORIGIN);

export const productUrl = Joi.string().max(255).label(S.WEBSITE_ADDRESS);

export const productCode = Joi.string().max(255).label(S.PRODUCT_CODE);

export const commodityCode = Joi.string().max(8).label(S.COMMODITY_CODE);

export const productType = Joi.string().max(40).label(S.PRODUCT_TYPE);

export const currency = Joi.string().max(3).label(S.CURRENCY);

export const productComposition = Joi.string()
  .max(200)
  .label(S.PRODUCT_COMPOSITION);

export const detailedProductDescription = Joi.string()
  .max(200)
  .label(S.DETAILED_PRODUCT_DESCRIPTION);

export const productWeight = Joi.number()
  .min(0.01)
  .max(99999.99)
  .error(
    joiExtension.messages({
      "number.min": ({ context }) =>
        formatMessage(
          S.PRODUCT_UNIT_WEIGHT_SHOULD_BE_MORE_OR_EQUAL_$,
          context.limit
        ),
      "number.max": ({ context }) =>
        formatMessage(
          S.PRODUCT_UNIT_WEIGHT_SHOULD_BE_LESS_OR_EQUAL_$,
          context.limit
        ),
    })
  )
  .label(S.PRODUCT_UNIT_WEIGHT);

// NOTE: Added additional validation for reflecting currency value in the error message
export const productUnitValue = Joi.number().label(S.PRODUCT_UNIT_VALUE);
