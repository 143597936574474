import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient, referenceApi } from "~/apis";

export const ActionTypes = createActionTypes("REFERENCE_DATA", {
  FETCH_COUNTRIES: createAsyncActionTypes("FETCH_COUNTRIES"),
  FETCH_CURRENCY: createAsyncActionTypes("FETCH_CURRENCY"),
  FETCH_COMMODITIES: createAsyncActionTypes("FETCH_COMMODITIES"),
  FETCH_COMMODITIES_BY_CODE: createAsyncActionTypes(
    "FETCH_COMMODITIES_BY_CODE"
  ),
});

export const fetchCountries = createAsyncAction(
  () => apisClient.reference.fetchCountries(),
  ActionTypes.FETCH_COUNTRIES
);

export const fetchCommodities = createAsyncAction(
  (query, options) => referenceApi.fetchCommodities(query, options),
  ActionTypes.FETCH_COMMODITIES
);

export const fetchCommoditiesByCode = createAsyncAction(
  (query, options) => referenceApi.fetchCommoditiesByCode(query, options),
  ActionTypes.FETCH_COMMODITIES_BY_CODE
);

export const fetchCurrencies = createAsyncAction(
  (query, options) => referenceApi.fetchCurrencies(query, options),
  ActionTypes.FETCH_CURRENCY
);
