import { Redirect, Route } from "react-router";

import { NotFoundHandleSwitch } from "@dpdgroupuk/mydpd-app";

import ProductBook from "~/pages/ProductBook";

import * as routes from "./constants";

export const Routes = () => (
  <NotFoundHandleSwitch>
    <Redirect exact from="/" to={routes.PRODUCTS} />
    <Route path={`${routes.PRODUCTS}/:id?`} component={ProductBook} />
  </NotFoundHandleSwitch>
);
