import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { apisClient } from "~/apis";

export const UmsActionTypes = createActionTypes("UMS", {
  FETCH_PREFERENCES: createAsyncActionTypes("FETCH_PREFERENCES"),
});

export const fetchPreferences = createAsyncAction(
  () =>
    Promise.all([
      apisClient.ums.fetchPreferences().then(({ data }) => data),
      apisClient.ums.fetchAdvanceConfiguration().then(({ data }) => data),
    ]).then(([preferences, advancedCustomerConfiguration]) => ({
      ...preferences,
      advancedCustomerConfiguration,
    })),
  UmsActionTypes.FETCH_PREFERENCES
);
