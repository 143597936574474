import api from "./api";

export const fetchProducts = (query, options) =>
  api.get({
    path: "/productbook",
    query,
    options,
  });

export const createProduct = (body, options) =>
  api.post({
    path: "/productbook",
    body,
    options,
  });

export const deleteAllProductBook = options =>
  api.delete({
    path: "/productbook",
    options,
  });

export const editProduct = (id, body, options) =>
  api.put({
    path: `/productbook/${id}`,
    body,
    options,
  });

export const deleteProductBookById = (id, query, options) =>
  api.delete({
    path: `/productbook/${id}`,
    options,
  });

export const importProductBook = (body, query) =>
  api.sendFormData({
    path: "/productbook/import",
    body,
    query,
  });
