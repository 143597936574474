import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./Checkbox.module.scss";

const CheckboxWithLabel = ({ label, disabled, readonly, ...props }) => (
  <label
    className={classNames(
      styles.container,
      props.className,
      readonly && styles.readonly,
      disabled && styles.disabled
    )}
  >
    <input
      className={styles.chekbox}
      type="checkbox"
      {...props}
      disabled={disabled || readonly}
    />
    <span className={styles.label}>{label}</span>
    <span className={styles.checkmark}></span>
  </label>
);

CheckboxWithLabel.propTypes = {
  label: PropTypes.string,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
};

export const ReduxifiedCheckboxWithLabel = ({ input, ...inputProps }) => (
  <CheckboxWithLabel {...input} {...inputProps} />
);

ReduxifiedCheckboxWithLabel.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
};

export default CheckboxWithLabel;
