import { attr, Model } from "redux-orm";

import { ProductBookImportFields } from "~/constants/forms";

import ENTITY_NAME from "../entities";
import ActionTypes from "./actionTypes";

export const preparingImportRequest = params => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  const query = {
    [ProductBookImportFields.IMPORT_OPTIONS]:
      params[ProductBookImportFields.IMPORT_OPTIONS],
  };
  formData.append(
    ProductBookImportFields.PRODUCT_BOOK_FILE,
    params[ProductBookImportFields.PRODUCT_BOOK_FILE]
  );
  return {
    formData,
    query,
  };
};

export class ProductBook extends Model {
  static reducer(action, ProductBookModel) {
    switch (action.type) {
      case ActionTypes.SEARCH.SUCCESS:
        ProductBookModel.all().delete();
        action.payload.results.forEach(product => {
          ProductBookModel.upsert(product);
        });
        break;
      case ActionTypes.DELETE.SUCCESS:
        ProductBookModel.withId(action.payload.productBookId).delete();
        break;
      case ActionTypes.UPDATE.SUCCESS:
        ProductBookModel.withId(action.payload.productBookId).update(
          action.payload.body
        );
        break;
    }
  }

  static modelName = ENTITY_NAME.PRODUCT_BOOK;

  static fields = {
    productBookId: attr(),
  };

  static options = {
    idAttribute: "productBookId",
  };
}
