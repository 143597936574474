import { ProductBookEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { formatMessage } from "~/utils/string";

export const createErrorBody = (path, message) => ({
  path,
  message,
});

export const validateProductUnitValue = values => {
  if (values.unitValue && values.unitValue <= 0.01) {
    return createErrorBody(
      ProductBookEntity.UNIT_VALUE,
      formatMessage(
        S.PRODUCT_UNIT_VALUE_SHOULD_BE_MORE_OR_EQUAL_$_$,
        values.currency,
        "0.01"
      )
    );
  }

  if (values.unitValue > 99999.99) {
    return createErrorBody(
      ProductBookEntity.UNIT_VALUE,
      formatMessage(
        S.PRODUCT_UNIT_VALUE_SHOULD_BE_LESS_OR_EQUAL_$_$,
        values.currency,
        "99999.99"
      )
    );
  }
};
