import get from "lodash/get";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";

import { Card, Legend, Main } from "@dpdgroupuk/mydpd-ui";

import { PLEASE_COMPLETE_ALL_REQUIRED_FIELDS } from "~/constants/strings";

import SearchAndSelect from "./SearchAndSelect";

const BaseBook = props => (
  <Main.Body>
    <Legend
      leftMessage={PLEASE_COMPLETE_ALL_REQUIRED_FIELDS}
      rightMessage={get(props.appUser, "username")}
    />
    <Card.Stack fluid>
      <Col md={6}>
        <SearchAndSelect {...props} />
      </Col>
      {props.children}
    </Card.Stack>
  </Main.Body>
);

BaseBook.propTypes = {
  footer: PropTypes.element,
  children: PropTypes.element,
  requiredFields: PropTypes.object,
  searchBooks: PropTypes.func,
  appUser: PropTypes.object,
};

export default BaseBook;
