import "react-bootstrap-typeahead/css/Typeahead.css";
import { useCallback, useRef } from "react";

import { isEmpty, startsWith } from "lodash";
import PropTypes from "prop-types";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import COUNTRIES from "~/constants/enums/countries";
import * as S from "~/constants/strings";

const CountriesAutocomplete = ({
  id,
  label,
  helperText,
  countries = [],
  input,
  required,
  disabled,
  selectedCountry,
  meta,
  onCountryChange,
  readonly,
}) => {
  const typeaheadRef = useRef(null);

  const onBlur = useCallback(
    event => {
      const blurValue = event.target.value?.toUpperCase();

      input.onBlur(blurValue);
      typeaheadRef.current?.blur();

      const firstFoundCountry = !isEmpty(blurValue)
        ? countries.find(({ label }) =>
            startsWith(label.toUpperCase(), blurValue)
          )
        : {};

      if (!isEmpty(firstFoundCountry)) {
        typeaheadRef?.current?.setState({
          text: firstFoundCountry?.label?.toUpperCase(),
        });
        onCountryChange(firstFoundCountry);
      } else {
        typeaheadRef?.current?.setState({
          text: S.UNITED_KINGDOM,
        });
        onCountryChange({ value: COUNTRIES.GB });
      }
    },
    [countries, input, onCountryChange]
  );

  const filterBy = useCallback(
    (option, autocompleteProps) =>
      startsWith(
        option.label.toUpperCase(),
        autocompleteProps?.text.toUpperCase()
      ),
    []
  );

  const handleSelectionChange = useCallback(
    selection => {
      typeaheadRef?.current?.blur();
      onBlur({ target: { value: selection.label } });
    },
    [onBlur]
  );

  const onKeyDown = useCallback(
    event => {
      if (event.code === "Enter") {
        typeaheadRef?.current?.blur();
        onBlur(event);
      }
    },
    [onBlur]
  );

  return (
    <FormControl.Autocomplete
      id={id}
      label={label}
      helperText={helperText}
      options={countries}
      labelKey={option => option.label.toUpperCase()}
      optionLabelMapper={option => option.label.toUpperCase()}
      onSelectionChange={handleSelectionChange}
      async={false}
      paginate={false}
      input={{
        ...input,
        value: selectedCountry?.label?.toUpperCase() || input.value,
        onBlur,
        onKeyDown,
      }}
      maxLength={35}
      type="dropdown"
      required={required}
      disabled={disabled}
      filterBy={filterBy}
      highlightOnlyResult
      meta={meta}
      normalize={value => value?.toUpperCase()}
      typeaheadRef={typeaheadRef}
      readonly={readonly}
    />
  );
};

CountriesAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  countries: PropTypes.array,
  input: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedCountry: PropTypes.object,
  meta: PropTypes.object,
  onCountryChange: PropTypes.func,
  readonly: PropTypes.bool,
};

export default CountriesAutocomplete;
