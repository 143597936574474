import { useCallback, useState } from "react";

import { useDispatch } from "react-redux";
import { reset } from "redux-form";

import ImportModal from "~/components/ImportModal";
import ImportResultModal from "~/components/ImportResultModal";
import { PRODUCT_FORM, PRODUCT_SEARCH_FORM } from "~/constants/forms";
import { PRODUCT_BOOK_IMPORT_RESULT } from "~/constants/strings";
import { PRODUCTS } from "~/router";

// eslint-disable-next-line react/display-name
const withImport = Component => props => {
  const [showImportModal, toggleImportModal] = useState(false);
  const [importResult, setImportResult] = useState(null);
  const dispatch = useDispatch();

  const openImportModal = useCallback(() => {
    toggleImportModal(true);
  }, []);

  const reloadPage = useCallback(() => {
    // eslint-disable-next-line react/prop-types
    props.history.push({
      pathname: PRODUCTS,
      state: {
        forceReload: true,
      },
    });
  }, []);

  const onSubmitImportResults = useCallback(() => {
    setImportResult(null);
    dispatch(reset(PRODUCT_SEARCH_FORM));
    dispatch(reset(PRODUCT_FORM));
    reloadPage();
  }, []);

  const closeImportModal = useCallback(reload => {
    if (reload) {
      reloadPage();
    }

    toggleImportModal(false);
  }, []);

  return (
    <>
      {showImportModal && (
        <ImportModal
          show
          onClose={closeImportModal}
          onSubmitImport={setImportResult}
        />
      )}
      {!!importResult && (
        <ImportResultModal
          show
          onSubmit={onSubmitImportResults}
          titleImportResult={PRODUCT_BOOK_IMPORT_RESULT}
          {...importResult}
        />
      )}
      <Component {...props} openImportModal={openImportModal} />
    </>
  );
};

export default withImport;
