import { createSelector } from "redux-orm";

import orm from "./orm";

export const propsSelector = (state, props) => props;

export const createSelectorOrm = (...args) => createSelector(orm, ...args);

export const createPropsSelectorOrm = (...args) =>
  createSelectorOrm(propsSelector, ...args);
