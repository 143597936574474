import { withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { PRODUCT_BOOK_ENTRY_PAGE } from "~/constants/analytics";
import { ProductBookEntity } from "~/constants/forms";

const MAP_FIELD_TO_ACTION_ID = {
  [ProductBookEntity.SHORT_NAME]: PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_SHORT_NAME,
  [ProductBookEntity.PRODUCT_CODE]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_PRODUCT_CODE,
  [ProductBookEntity.COMMODITY_CODE]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_COMMODITY_CODE,
  [ProductBookEntity.UNIT_VALUE]: PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_UNIT_VALUE,
  [ProductBookEntity.COUNTRY_OF_ORIGIN]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_SELECT_COUNTRY,
  [ProductBookEntity.PRODUCT_URL]: PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_PRODUCT_URL,
  [ProductBookEntity.PRODUCT_TYPE]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_PRODUCT_TYPE,
  [ProductBookEntity.PRODUCT_COMPOSITION]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_PRODUCT_COMPOSITION,
  [ProductBookEntity.PRODUCT_DESCRIPTION]:
    PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_PRODUCT_DESCRIPTION,
  [ProductBookEntity.UNIT_WEIGHT]: PRODUCT_BOOK_ENTRY_PAGE.ON_BLUR_UNIT_WEIGHT,
  [ProductBookEntity.CURRENCY]: PRODUCT_BOOK_ENTRY_PAGE.ON_SELECT_CURRENCY,
};

export default withTrackProps({
  onClickHelpIcon: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_PRODUCT_DETAILS_HELP,
  onDeleteClick: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_DELETE,
  onFindClick: PRODUCT_BOOK_ENTRY_PAGE.ON_CLICK_COMMODITY_FIND,
  onFieldEntry: (event, newValue, previousValue, name) =>
    MAP_FIELD_TO_ACTION_ID[name],
});
