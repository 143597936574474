import { SEARCH_AND_SELECT_HELP } from "~/constants/forms";
import { PRODUCT_INFO } from "~/constants/strings";

export default {
  [SEARCH_AND_SELECT_HELP]: [
    {
      name: "Product Book",
      content: "",
    },
    {
      name: "Search",
      content: "",
    },
    {
      name: "Hide Delivery Addresses",
      content: "",
    },
  ],
  [PRODUCT_INFO]: [
    {
      name: "Short Name",
      content: (
        <p>
          A unique short name that is used to search for, or identify, the
          product.
        </p>
      ),
    },
    {
      name: "Country Of Origin",
      content: (
        <p>
          The country in which the items were manufactured. Please note this is
          not where goods are being shipped or purchased from.
        </p>
      ),
    },
    {
      name: "Website Address",
      content: (
        <p>
          A URL where the item can be seen for sale, this is used by Customs in
          some countries to validate the transaction between you and the buyer
        </p>
      ),
    },
    {
      name: "Product Code",
      content: (
        <p>
          Your internal identifier of the product being shipped, sometimes known
          as a Stock Keeping Unit or SKU
        </p>
      ),
    },
    {
      name: "Commodity Code",
      content: (
        <p>
          The commodity or harmonised code for the item. The harmonised system
          is used globally to classify items. DPD requires HS codes to be to the
          detail level of 8 digits.
        </p>
      ),
    },
    {
      name: "Product Type",
      content: <p>A description of what the item is</p>,
    },
    {
      name: "Product Composition",
      content: (
        <p>
          Details of the fabric content of the item, used only when shipping
          textiles or apparel
        </p>
      ),
    },
    {
      name: "Detailed Product Description",
      content: (
        <p>
          A more detailed description of the item including what it&apos;s made
          from and its intended use e.g. Women&apos;s 100% cotton summer dress
        </p>
      ),
    },
    {
      name: "Product Unit Weight (Kg)",
      content: (
        <p>
          The unit weight of the a single unit of the item The sum of all item
          weights cannot exceed the declared weight provided
        </p>
      ),
    },
    {
      name: "Currency",
      content: (
        <p>
          This is the currency which we will use when declaring the goods to
          Customs. It will be applied to all values which you have given.
          Ideally this should be in the currency which the buyer has paid you
          in. If that currency in unavailable please state the values once
          converted to an available currencys
        </p>
      ),
    },
    {
      name: "Product Unit Value",
      content: (
        <p>
          The value of each unit of the item, this will use the currency you
          have selected
        </p>
      ),
    },
  ],
};
