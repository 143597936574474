import Joi from "joi";

import { Fields, SEARCH_CRITERIA_FIELD } from "~/constants/forms";

import {
  commodityCode,
  countryCode,
  currency,
  detailedProductDescription,
  productCode,
  productComposition,
  productType,
  shortName,
} from "./commonSchema";

export default Joi.object({
  searchCriteriaField: Joi.string(),
  searchCriteriaValue: Joi.alternatives()
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.SHORT_NAME,
      then: shortName,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.PRODUCT_DESCRIPTION,
      then: detailedProductDescription,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.COUNTRY_OF_ORIGIN,
      then: countryCode,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.PRODUCT_CODE,
      then: productCode,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.PRODUCT_TYPE,
      then: productType,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.PRODUCT_COMPOSITION,
      then: productComposition,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.COMMODITY_CODE,
      then: commodityCode,
    })
    .when(SEARCH_CRITERIA_FIELD, {
      is: Fields.CURRENCY,
      then: currency,
    }),
});
