import { useMemo } from "react";

import classNames from "classnames";
import { sortBy } from "lodash";
import PropTypes from "prop-types";
import { compose } from "recompose";

import { Button, Modal } from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import Table from "~/components/Table";
import { IMPORT_RESULT_POP_UP } from "~/constants/analytics";
import {
  ERROR,
  ERRORS,
  INSERTED,
  ISSUES,
  LINE,
  OK,
  SHORT_NAME,
  STATUS,
  WARNING,
  WARNINGS,
} from "~/constants/strings";

import styles from "./ImportResultModal.module.scss";
import { transformImportResult } from "./model";

const columns = [
  {
    text: LINE,
    dataIndex: "line",
    sortable: true,
    style: {
      width: "60px",
    },
  },
  {
    text: SHORT_NAME,
    render: ({ rowData: { shortName } }) => shortName?.toUpperCase(),
    dataIndex: "shortName",
    sortable: true,
    style: {
      width: "140px",
    },
  },
  {
    text: ISSUES,
    sortable: true,
    dataIndex: "issue",
    render: ({ rowData: { errors, warnings } }) => {
      const errorMessageContainer = errors?.length > 0 ? errors : warnings;
      return errorMessageContainer.map((val, key) => (
        <div key={key}>{val.message.toUpperCase()}</div>
      ));
    },
    sorter: (dataIndex, order, data) =>
      sortBy(
        data.map(item => {
          const errorMessageContainer =
            item.errors?.length > 0 ? item.errors : item.warnings;
          const messages = errorMessageContainer
            .map(val => val.message)
            .join(",");
          return {
            ...item,
            issue: messages,
          };
        }),
        "issue"
      ),
  },
  {
    text: STATUS,
    sortable: true,
    dataIndex: "status",
    render: ({ rowData: { errors, warnings } }) => {
      if (errors?.length > 0) {
        return ERROR.toUpperCase();
      } else if (warnings?.length > 0) {
        return WARNING.toUpperCase();
      }
    },
    sorter: (dataIndex, order, data) =>
      sortBy(
        // FIXME add default return and remove eslint-disable
        // eslint-disable-next-line array-callback-return
        data.map(item => {
          if (item.errors?.length > 0) {
            return {
              ...item,
              status: ERROR,
            };
          }
          if (item.warnings?.length > 0) {
            return {
              ...item,
              status: WARNING,
            };
          }
        }),
        "status"
      ),
    style: {
      width: "100px",
    },
  },
];

const ImportResultModal = ({
  inserted,
  errors,
  warnings,
  import: imported,
  show,
  onSubmit,
  onSortColumn,
  onHide,
  titleImportResult,
}) => {
  const transformedIssuesData = useMemo(
    () => transformImportResult(imported),
    [imported]
  );

  return (
    <Modal
      dialogClassName={classNames(styles.modal)}
      show={show}
      onHide={onHide}
    >
      <Modal.Header>{titleImportResult}</Modal.Header>
      <Modal.Body className={classNames(styles.modalBody)}>
        <div className={styles.stats}>
          <div className={styles.item}>
            <p>{INSERTED}</p>
            <span className={styles.label}>{inserted}</span>
          </div>
          <div className={styles.item}>
            <p>{WARNINGS}</p>
            <span className={styles.labelWarning}>{warnings}</span>
          </div>
          <div className={styles.item}>
            <p>{ERRORS}</p>
            <span className={styles.labelError}>{errors}</span>
          </div>
        </div>

        {(errors > 0 || warnings > 0) && (
          <div className={styles.table}>
            <Table
              data={transformedIssuesData}
              columns={columns}
              showPaginator={false}
              onSortColumn={onSortColumn}
              tableContainerClassName={styles.tableContainer}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSubmit}>
          {OK}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ImportResultModal.propTypes = {
  inserted: PropTypes.number,
  errors: PropTypes.number,
  warnings: PropTypes.number,
  import: PropTypes.array,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSortColumn: PropTypes.func,
  onSubmit: PropTypes.func,
  titleImportResult: PropTypes.string,
};

ImportResultModal.defaultProps = {
  onHide: () => null,
};

export default compose(
  withTrack(trackProps(IMPORT_RESULT_POP_UP)),
  withTrackProps({
    onHide: IMPORT_RESULT_POP_UP.CLICK_CLOSE,
    onSubmit: IMPORT_RESULT_POP_UP.ON_CLICK_YES,
    onSortColumn: IMPORT_RESULT_POP_UP.CLICK_SORT_ICON,
  })
)(ImportResultModal);
