import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  AppLoader,
  NotifierProvider,
  PROJECT_NAME,
  Provider,
} from "@dpdgroupuk/mydpd-app";
import { Layout, Main } from "@dpdgroupuk/mydpd-ui";

import { apisClient } from "~/apis";

import Header from "./Header";
import { UmsActions } from "./redux";
import { Routes } from "./router";
import tracker from "./tracker";
import "./sentry";

function App({ fetchInitialData }) {
  return (
    <Provider
      enableHelperTextTooltip
      apis={apisClient}
      tracker={tracker}
      projectName={PROJECT_NAME.DATA}
    >
      <AppLoader loadFn={fetchInitialData}>
        <Layout header={<Header />}>
          <Main>
            <NotifierProvider>
              <Routes />
            </NotifierProvider>
          </Main>
        </Layout>
      </AppLoader>
    </Provider>
  );
}

App.propTypes = {
  fetchInitialData: PropTypes.func,
};

const mapDispatchToProps = {
  fetchInitialData: UmsActions.fetchPreferences,
};

export default connect(null, mapDispatchToProps)(App);
