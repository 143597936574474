import classNames from "classnames";
import PropTypes from "prop-types";

import { Table as BorderlessTable, Paginator } from "@dpdgroupuk/mydpd-ui";

import styles from "./Table.module.scss";

const Table = ({
  data,
  columns,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onFirst,
  onLast,
  showPaginator,
  onSortColumn,
  isValidItem,
  isActiveItem,
  ...restTableProps
}) => {
  const getClassnames = data => {
    const isValid = isValidItem ? isValidItem(data) : true;
    const isActive = isActiveItem ? isActiveItem(data) : false;

    if (isValid) {
      return classNames(styles.tableRow, isActive && styles.tableRowActive);
    } else {
      return classNames(
        styles.tableRow,
        styles.tableRowInvalid,
        isActive && styles.tableRowInvalidActive
      );
    }
  };

  return (
    <BorderlessTable
      data={data}
      columns={columns}
      tableContainerClassName={styles.wrapper}
      applyRowStyles={getClassnames}
      onSortColumn={onSortColumn}
      {...restTableProps}
    >
      {showPaginator && (
        <Paginator
          containerClassName={styles.paginator}
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          onPrevious={onPrevious}
          onNext={onNext}
          onFirst={onFirst}
          onLast={onLast}
        />
      )}
    </BorderlessTable>
  );
};

Table.defaultProps = {
  showPaginator: true,
};

Table.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  page: PropTypes.number,
  totalCount: PropTypes.number,
  pageSize: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onFirst: PropTypes.func,
  onLast: PropTypes.func,
  onSortColumn: PropTypes.func,
  isValidItem: PropTypes.func,
  isActiveItem: PropTypes.func,
  showPaginator: PropTypes.bool,
};

export default Table;
