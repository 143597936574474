import classNames from "classnames";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import { propTypes } from "redux-form";

import { Button } from "@dpdgroupuk/mydpd-ui";

import UpdatesAndSave from "~/components/UpdatesAndSave/UpdatesAndSave";
import * as S from "~/constants/strings";
import { DELETE_RECORD } from "~/constants/strings";
import BaseBook from "~/features/BaseBook/BaseBook";
import ProductCard from "~/pages/ProductBook/components/ProductCard";

import styles from "./ProductPage.module.scss";

const ProductPage = ({ searchProducts, onDeleteClick, ...props }) => (
  <>
    <BaseBook
      {...props}
      helpModalTitle={S.SEARCH_AND_SELECT_PRODUCT}
      searchBooks={searchProducts}
      title={S.SEARCH_AND_SELECT_PRODUCT}
    >
      <Col md={6}>
        <ProductCard {...props}>
          <div className={classNames(styles.footer)}>
            <Button
              disabled={!props.isEdit || props.isReadonly || !props.initialized}
              variant="danger"
              onClick={onDeleteClick.bind({}, props)}
            >
              {DELETE_RECORD}
            </Button>
          </div>
        </ProductCard>
      </Col>
    </BaseBook>
    <UpdatesAndSave {...props} />
  </>
);

ProductPage.propTypes = {
  searchProducts: PropTypes.func,
  ...propTypes,
};

export default ProductPage;
