import { get, isEmpty, omit, pick, valuesIn } from "lodash";
import queryString from "query-string";

import { FilterFields } from "~/constants/forms";

import { omitNilValues } from "./object";

export const INITIAL_PAGE_QUERY = {
  searchPage: 1,
  searchPageSize: 17,
  type: 0,
  addressesSearchPage: 1,
  addressesSearchPageSize: 15,
};

export const parseQuery = (location, parseNumbers, parseBooleans = true) => {
  const query = queryString.parse(location.search, {
    parseNumbers,
    parseBooleans,
  });

  return {
    ...INITIAL_PAGE_QUERY,
    ...query,
  };
};

export const omitLocationSearch = (
  search,
  fields,
  { parseNumbers, parseBooleans = true } = {}
) => {
  const query = queryString.parse(search, {
    parseNumbers,
    parseBooleans,
  });
  return queryString.stringify(omit(query, fields));
};

export const stringifyQuery = (query, encode = true) =>
  queryString.stringify(omitNilValues(query), {
    encode,
  });

export const getQueryFilters = location => {
  const query = parseQuery(location, false);
  const res = pick(query, valuesIn(FilterFields));

  return isEmpty(res) ? {} : omitNilValues(res);
};

export const getPathId = (match, path) => get(match, path, null);

export const getQueryParam = (location, param, defValue) => {
  const query = parseQuery(location, false);
  return get(query, param, defValue);
};

export const getQueryPagination = (
  location,
  values = ["searchPage", "searchPageSize"]
) => {
  const query = parseQuery(location, false);
  const res = pick(query, values);

  return isEmpty(res) ? {} : res;
};
