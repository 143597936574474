import { cloneDeep, set } from "lodash";

export default (exclusions = []) =>
  (state, action) => {
    switch (action.type) {
      case "@@redux-form/BLUR":
      case "@@redux-form/AUTOFILL":
        if (
          typeof action.payload === "string" &&
          !exclusions.includes(action.meta.field)
        ) {
          const values = cloneDeep(state.values);

          set(values, action.meta.field, action.payload.toUpperCase());
          return {
            ...state,
            values,
          };
        }
        return state;
      default:
        return state;
    }
  };
