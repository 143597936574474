import { ProductActionTypes } from "~/redux/orm";

export const initialState = {
  totalResults: 0,
  importErrors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ProductActionTypes.SEARCH.SUCCESS: {
      return {
        ...state,
        totalResults: action.payload.totalResults,
      };
    }
    case ProductActionTypes.DELETE.SUCCESS: {
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };
    }
    case ProductActionTypes.CREATE.SUCCESS: {
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    }
    case ProductActionTypes.DELETE_ALL.SUCCESS: {
      return {
        ...state,
        totalResults: 0,
      };
    }
    case ProductActionTypes.CLEAR_IMPORT_ERRORS:
    case ProductActionTypes.IMPORT.REQUEST: {
      return {
        ...state,
        importErrors: [],
      };
    }
    default:
      return state;
  }
};
