import { createAsyncAction } from "@dpdgroupuk/redux-action-creator";

import { productBookApi } from "~/apis";
import { Fields } from "~/constants/forms";
import { preparingImportRequest } from "~/redux/orm/Product/models";

import ActionTypes from "./actionTypes";

export const searchProducts = createAsyncAction(async (query, fetchOptions) => {
  const { data } = await productBookApi.fetchProducts(
    { ...query, searchSort: Fields.SHORT_NAME },
    fetchOptions
  );

  return data;
}, ActionTypes.SEARCH);

export const updateProductBook = createAsyncAction(
  async (productBookId, body, options) => {
    await productBookApi.editProduct(productBookId, body, options);

    return {
      productBookId,
      ...body,
    };
  },
  ActionTypes.UPDATE
);

export const createProductBook = createAsyncAction(async (body, options) => {
  const { data } = await productBookApi.createProduct(body, options);

  return {
    productBookId: data.productBookId,
    ...body,
  };
}, ActionTypes.CREATE);

export const deleteProductBookById = createAsyncAction(async productBookId => {
  await productBookApi.deleteProductBookById(productBookId);

  return { productBookId };
}, ActionTypes.DELETE);

export const deleteAllProductBook = createAsyncAction(
  productBookApi.deleteAllProductBook,
  ActionTypes.DELETE_ALL
);

export const importProductBook = createAsyncAction(async params => {
  const { formData, query } = preparingImportRequest(params);

  return await productBookApi.importProductBook(formData, query);
}, ActionTypes.IMPORT);

export const clearImportErrors = () => ({
  type: ActionTypes.CLEAR_IMPORT_ERRORS,
});
