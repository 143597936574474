import { get } from "lodash";
import { getFormSyncErrors, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import {
  BOOK_SEARCH_FORM,
  PRODUCT_FORM,
  PRODUCT_IMPORT_FORM,
  Fields as ProductBookEntity,
  ProductBookImportFields,
} from "~/constants/forms";
import { ReferenceDataSelectors, UmsSelectors } from "~/redux";

import * as model from "./models";

export const getProductBookValues = createSelector(
  getFormValues(PRODUCT_FORM),
  values => values
);

export const getCountryCode = createSelector(
  getProductBookValues,
  values => get(values, "countryOfOrigin", "") // TODO check it
);

export const getSelectedCurrency = createSelector(
  getProductBookValues,
  values => get(values, "currency", "")
);

export const getCountriesKeyValue = createSelector(
  ReferenceDataSelectors.getCountries,
  model.mapCountriesToKeyValue
);

export const getCurrenciesKeyValue = createSelector(
  ReferenceDataSelectors.getCurrencies,
  model.mapCurrenciesToKeyValue
);

export const getSelectedCreateCountry = createSelector(
  getCountryCode,
  getCountriesKeyValue,
  model.getCountryByCountryCode
);

export const isUserReadonlyProductBookPermissions = createSelector(
  UmsSelectors.getPreferences,
  preferences =>
    !!get(
      preferences,
      "advancedCustomerConfiguration.readOnlyProductBook",
      false
    )
);

export const getData = state => state.ui.productBook;

export const getProductBooksTotalResults = createSelector(
  getData,
  ({ totalResults }) => totalResults
);

export const getProductRequiredFields = () => ({
  [ProductBookEntity.SHORT_NAME]: true,
});

export const isUserReadonlyPermissions = createSelector(
  UmsSelectors.getPreferences,
  preferences =>
    !!get(
      preferences,
      "advancedCustomerConfiguration.readOnlyProductBook",
      false
    )
);

export const getImportOptionValue = createSelector(
  getFormValues(PRODUCT_IMPORT_FORM),
  values => values[ProductBookImportFields.IMPORT_OPTIONS]
);

export const getSearchFormErrors = createSelector(
  getFormSyncErrors(BOOK_SEARCH_FORM),
  errors => errors
);
