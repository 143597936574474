// Menu
export const ADDRESS_BOOK = "Address book";
export const GROUPS = "Groups";
export const PRODUCTS = "Products";
export const SEARCH = "Search";

// Titles
export const EDIT_PRODUCT = "Edit Product";
export const CREATE_PRODUCT = "Create Product";
export const PRODUCT_DETAILS = "Product Details";
export const SEARCH_AND_SELECT_PRODUCT = "Search & Select Product";

// Card Info
export const PRODUCT_INFO = "PRODUCT_INFO";

// Labels
export const CANCEL = "Cancel";
export const CONFIRM = "Confirm";
export const OK = "Ok";
export const YES = "Yes";
export const NO = "No";
export const SAVE = "Save";
export const DELETE_ALL = "Delete All";
export const NEW = "New";
export const SHORT_NAME = "Short Name";
export const COUNTRY_OF_ORIGIN = "Country of Origin";
export const WEBSITE_ADDRESS = "Website Address";
export const CURRENCY = "Currency";
export const WEBSITE = "Website";
export const PRODUCT_CODE = "Product Code";
export const COMMODITY_CODE = "Commodity Code";
export const PRODUCT_TYPE = "Product Type";
export const PRODUCT_COMPOSITION = "Product Composition";
export const COMPOSITION = "Composition";
export const DETAILED_PRODUCT_DESCRIPTION = "Detailed Product Description";
export const DESCRIPTION = "Description";
export const PRODUCT_UNIT_WEIGHT = "Product Weight (KG)";
export const WEIGHT = "Weight";
export const PRODUCT_UNIT_VALUE = "Product Unit Value";
export const PRODUCT_UNIT_VALUE_CURRENCY = `${PRODUCT_UNIT_VALUE} ({0})`;
export const LINE = "Line";
export const ERROR = "Error";
export const ERRORS = "Errors";
export const INSERTED = "Inserted";
export const WARNING = "Warning";
export const WARNINGS = "Warnings";
export const ISSUES = "Issues";
export const STATUS = "Status";
export const COUNTRY = "Country";
export const TYPE = "Type";
export const PRODUCT_CODE_SKU = "Product CODE / SKU";
export const COMMODITY = "Commodity";
export const UNIT_VALUE = "Unit Value";
export const DELETE_RECORD = "Delete Record";
export const IMPORT = "Import";
export const PRODUCT_BOOK_IMPORT = "product book import";
export const PRODUCT_BOOK_IMPORT_RESULT = "Product Book Import Results";
export const IMPORT_OPTIONS = "import options";
export const UPLOAD = "Upload";
export const FILE_MUST_BE_SELECTED = "File must be selected";
export const IMPORT_NEW_ONLY = "import new only (reject duplicates)";
export const FILE_LOCATION = "File Location";
export const SEARCH_LABEL = "Search For";
export const OVERWRITE_PRODUCT = "overwrite existing products";
export const EMPTY_PRODUCT = "empty product book before import";
export const UPLOADING_PRODUCT_BOOK = "Uploading product book...";

// Messages
export const CONFIRM_PRODUCT_BOOK_DELETION = "Confirm Product Book Deletion";
export const THERE_IS_NO_WAY_TO_RECOVER_PRODUCTBOOK =
  "There is no way to recover your product book data once you’ve confirmed this action";
export const CONFIRM_PRODUCT_DELETION = "Confirm Product Deletion";
export const ARE_YOU_SURE_TO_DELETE_PRODUCTBOOK_ENTRY =
  "Are you sure you wish to delete this entry in your product book?";
export const ARE_YOU_SURE_TO_DELETE_ALL_PRODUCTBOOK =
  "Are you sure you wish to delete all entries in your product book?";
export const ENTER_OR_SELECT_DESTINATION_COUNTRY =
  "Enter or select the destination country";
export const ENTER_SHORT_NAME = "Enter your product book short name";
export const ENTER_PRODUCT_URL = "Website URL for Product (Parcels to Russia)";
export const ENTER_PRODUCT_CODE_IDENTIFIER =
  "Enter the product code identifier (SKU)";
export const CLARIFY_YOUR_GOODS =
  "Classify your goods correctly for your export declaration";
export const PRODUCT_TYPE_EXAMPLE = "Example: Women’s bags/men’s shoes";
export const PRODUCT_COMPOSITION_EXAMPLE = "Example: Woven 100% silk";
export const DETAILED_PRODUCT_DESCRIPTION_EXAMPLE =
  "Example: Men’s patent leather shoes";
export const MAXIMUM_TOTAL_WEIGHT_PER_PACKAGE =
  "The maximum total weight per package is 30Kg";
export const PRODUCT_UNIT_VALUE_EXAMPLE =
  "The value for each product in this parcel";
export const SELECT_PRODUCT_CURRENCY = "Select product currency";
export const UPLOAD_FILE_CHANGED =
  "File has been changed. Please try to select again.";

// Hints
export const UPPERCASE = "uppercase";
export const UNITED_KINGDOM = "UNITED KINGDOM";

// Snackbar messages
export const FAILED_TO_FETCH = "Failed to fetch";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const FAILED_TO_FETCH_ERROR_MESSAGE_$ = (failedToFetchEntityName = "") =>
  `${FAILED_TO_FETCH} ${failedToFetchEntityName}`;
export const COUNTRIES = "countries";
export const PRODUCTS_DELETED = "Products Deleted";
export const PLEASE_COMPLETE_ALL_REQUIRED_FIELDS =
  "Please complete all required fields as indicated *";
export const EDIT_PRODUCT_BOOK_WAS_UPDATED = "Record has been updated";
export const EDIT_PRODUCT_BOOK_WAS_CREATED =
  "1 New product record has been created";

// Joi Validation
export const joiMessages = {
  any: {
    required: "{{label}} must be entered",
  },
  string: {
    max: "{{label}} must be a maximum of {{limit}} characters",
    min: "{{label}} must be more than {{limit}} characters",
    empty: "{{label}} must be entered",
    regex: {
      name: "A valid {{label}} must be entered",
    },
  },
  number: {
    base: "{{label}} must be a number",
    min: "{{label}} must be more or equal {{limit}}",
    max: "{{label}} must be a maximum of {{limit}}",
    greater: "{{label}} must be equal or greater than {{limit}}",
    less: "{{label}} must be equal or less than {{limit}}",
  },
};

export const PRODUCT_UNIT_WEIGHT_SHOULD_BE_MORE_OR_EQUAL_$ =
  "Product Weight (KG) must be more or equal to {0} ";

export const PRODUCT_UNIT_VALUE_SHOULD_BE_MORE_OR_EQUAL_$_$ =
  "Product Unit Value ({0}) must be more or equal to {1} ";

export const PRODUCT_UNIT_WEIGHT_SHOULD_BE_LESS_OR_EQUAL_$ =
  "Product Weight (KG) must be less or equal to {0} ";

export const PRODUCT_UNIT_VALUE_SHOULD_BE_LESS_OR_EQUAL_$_$ =
  "Product Unit Value ({0}) must be less or equal to {1} ";
