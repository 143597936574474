import classNames from "classnames";

import styles from "./ReadOnlyHeader.module.scss";

const ReadOnlyHeader = () => (
  <div className={classNames(styles.header)}>
    - (<span>Read Only</span>)
  </div>
);

export default ReadOnlyHeader;
