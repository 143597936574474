import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import { createReducer } from "redux-orm";

import { combineAppReducer } from "@dpdgroupuk/mydpd-app";

import { PRODUCT_FORM, ProductBookEntity } from "~/constants/forms";
import { productModel } from "~/models";
import { floatRounding, toUppercase } from "~/redux/reduxForm/plagins";

import ProductBookReducer from "../pages/ProductBook/reducer";
import orm from "./orm/orm";
import { ReferenceDataReducer } from "./reference";
import { UmsReducer } from "./ums";

const combinePlugins = plugins => (state, action) =>
  plugins.reduce((acc, curr) => curr(acc, action), state);

export default combineReducers({
  form: form.plugin({
    [PRODUCT_FORM]: combinePlugins([
      toUppercase([ProductBookEntity.PRODUCT_URL]),
      floatRounding([
        productModel.isProductUnitValue,
        productModel.isProductUnitWeight,
      ]),
    ]),
  }),
  orm: createReducer(orm),
  ui: combineReducers({
    productBook: ProductBookReducer,
  }),
  app: combineAppReducer({
    ums: UmsReducer,
    referenceData: ReferenceDataReducer,
  }),
});
