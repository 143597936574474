import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { compose, withProps, withState } from "recompose";
import { Field, reduxForm, SubmissionError } from "redux-form";

import { withNotifier } from "@dpdgroupuk/mydpd-app";
import { Button, Modal, withSnackbar } from "@dpdgroupuk/mydpd-ui";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";

import FileInput from "~/components/FileInput/FileInput";
import RadioInput from "~/components/RadioInput";
import { IMPORT_PRODUCT_BOOK_MODAL } from "~/constants/analytics";
import {
  ImportOptionsValue,
  PRODUCT_IMPORT_FORM,
  ProductBookImportFields,
} from "~/constants/forms";
import { SHOW_ALERT_DISPLAY_TIME } from "~/constants/snackbar";
import {
  CANCEL,
  EMPTY_PRODUCT,
  FILE_LOCATION,
  IMPORT_NEW_ONLY,
  IMPORT_OPTIONS,
  OVERWRITE_PRODUCT,
  PRODUCT_BOOK_IMPORT,
  UPLOAD,
  UPLOAD_FILE_CHANGED,
  UPLOADING_PRODUCT_BOOK,
} from "~/constants/strings";
import { importProductValidators } from "~/models/validators";
import { getImportOptionValue } from "~/pages/ProductBook/selectors";
import { ProductActions } from "~/redux";

import styles from "./ImportModal.module.scss";

const ImportModal = ({
  show,
  onHide,
  onFileChange,
  onSelectFile,
  onChangeImportOptions,
  submitting,
  handleSubmit,
  onSubmit,
  onCloseModal,
}) => (
  <Modal dialogClassName={classNames(styles.modal)} show={show} onHide={onHide}>
    <Modal.Header>{PRODUCT_BOOK_IMPORT}</Modal.Header>
    <Modal.Body>
      <Container className="p-0" fluid>
        <Row>
          <Col sm={3}>
            <p className={classNames(styles.subHeader)}>{FILE_LOCATION}</p>
          </Col>
          <Col sm={9}>
            <Field
              component={FileInput}
              label={FILE_LOCATION}
              accept=".csv"
              name={ProductBookImportFields.PRODUCT_BOOK_FILE}
              onChange={onFileChange}
              onSelectFile={onSelectFile}
              classes={{
                group: styles.fileInput,
                container: styles.fileInputContainer,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <p className={classNames(styles.subHeader)}>{IMPORT_OPTIONS}</p>
          </Col>
          <Col sm={9}>
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={IMPORT_NEW_ONLY}
              name={ProductBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.IMPORT_NEW_ONLY}
              type="radio"
              onChange={onChangeImportOptions}
            />
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={OVERWRITE_PRODUCT}
              name={ProductBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.OVERWRITE}
              type="radio"
              onChange={onChangeImportOptions}
            />
            <Field
              classNameLabel={classNames(styles.radioInput)}
              component={RadioInput}
              label={EMPTY_PRODUCT}
              name={ProductBookImportFields.IMPORT_OPTIONS}
              value={ImportOptionsValue.EMPTY}
              type="radio"
              onChange={onChangeImportOptions}
            />
          </Col>
        </Row>
      </Container>
    </Modal.Body>
    <Modal.Footer>
      {!submitting && (
        <>
          <Button variant="dark" onClick={onCloseModal}>
            {CANCEL}
          </Button>
          <Button variant="primary" onClick={handleSubmit(onSubmit)}>
            {UPLOAD}
          </Button>
        </>
      )}
      {!!submitting && (
        <ProgressBar
          animated
          now={100}
          label={UPLOADING_PRODUCT_BOOK}
          className={styles.progress}
        />
      )}
    </Modal.Footer>
  </Modal>
);
ImportModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onFileChange: PropTypes.func,
  onSelectFile: PropTypes.func,
  onChangeImportOptions: PropTypes.func,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onCloseModal: PropTypes.func,
};

ImportModal.defaultProps = {
  onHide: () => null,
};

export default compose(
  withState("reload", "setReload", false),
  withNotifier,
  withSnackbar,
  reduxForm({
    form: PRODUCT_IMPORT_FORM,
    validate: importProductValidators.importProduct,
    initialValues: {
      [ProductBookImportFields.IMPORT_OPTIONS]:
        ImportOptionsValue.IMPORT_NEW_ONLY,
    },
  }),
  connect(
    state => ({
      importValue: getImportOptionValue(state),
    }),
    (dispatch, { notifier, setReload, snackbar }) => ({
      onSubmit: notifier.runAsync(
        async (values, dispatch, { onClose, onSubmitImport }) => {
          // If file has been changed and you will try to upload - Chrome will throw an error "ERR_UPLOAD_FILE_CHANGED"
          try {
            await values[ProductBookImportFields.PRODUCT_BOOK_FILE]
              .slice(0, 1) // only the first byte
              .arrayBuffer();
          } catch (error) {
            return snackbar.showAlert({
              message: UPLOAD_FILE_CHANGED,
              displayTime: SHOW_ALERT_DISPLAY_TIME,
            });
          }

          try {
            const { data } = await dispatch(
              ProductActions.importProductBook(values)
            );
            setReload(false);
            onSubmitImport(data);
            onClose();
          } catch (err) {
            setReload(values.updateType === ImportOptionsValue.EMPTY);
            const { code, message } = err[0] || {};
            if (message && code === 103) {
              throw new SubmissionError({
                productBookFile: message,
              });
            } else {
              throw err;
            }
          }
        }
      ),
      clearErrors: () => dispatch(ProductActions.clearImportErrors),
    })
  ),
  withProps(({ clearErrors, onClose, reload }) => ({
    onCloseModal: () => {
      onClose(reload);
      clearErrors();
    },
    onHide: onClose,
  })),
  withTrack(trackProps(IMPORT_PRODUCT_BOOK_MODAL)),
  withTrackProps({
    onChangeImportOptions: event => {
      const value = event.target.value;
      switch (value) {
        case ImportOptionsValue.IMPORT_NEW_ONLY:
          return IMPORT_PRODUCT_BOOK_MODAL.SELECT_IMPORT_NEW_ONLY;
        case ImportOptionsValue.OVERWRITE:
          return IMPORT_PRODUCT_BOOK_MODAL.SELECT_OVERWRITE_PRODUCT;
        case ImportOptionsValue.EMPTY:
          return IMPORT_PRODUCT_BOOK_MODAL.SELECT_EMPTY_PRODUCT;
      }
    },
    onSelectFile: IMPORT_PRODUCT_BOOK_MODAL.CLICK_SELECT_FILE,
    onCloseModal: IMPORT_PRODUCT_BOOK_MODAL.CLICK_CLOSE,
    onSubmit: IMPORT_PRODUCT_BOOK_MODAL.CLICK_UPLOAD,
  })
)(ImportModal);
