let timeout;

export default (fn, delay = 500) =>
  (...args) => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      clearTimeout(timeout);
      fn(...args);
    }, delay);
  };
