import { get } from "lodash";

import { roundToDecimal } from "~/utils/numbers";

import { ProductBookEntity } from "./forms";
import {
  COMMODITY,
  COMPOSITION,
  COUNTRY,
  CURRENCY,
  DESCRIPTION,
  PRODUCT_CODE_SKU,
  SHORT_NAME,
  TYPE,
  UNIT_VALUE,
  WEBSITE,
  WEIGHT,
} from "./strings";

export const PRODUCT_COLUMNS = [
  {
    Header: SHORT_NAME,
    accessor: ProductBookEntity.SHORT_NAME,
    width: 98,
  },
  {
    Header: PRODUCT_CODE_SKU,
    accessor: ProductBookEntity.PRODUCT_CODE,
    width: 90,
  },
  {
    Header: COMMODITY,
    accessor: ProductBookEntity.COMMODITY_CODE,
    width: 80,
  },
  {
    Header: UNIT_VALUE,
    accessor: ProductBookEntity.UNIT_VALUE,
    width: 80,
    Cell: ({ row: { original } }) =>
      roundToDecimal(get(original, ProductBookEntity.UNIT_VALUE)),
  },
  {
    Header: COUNTRY,
    accessor: ProductBookEntity.COUNTRY_OF_ORIGIN,
    width: 80,
  },
  {
    Header: WEBSITE,
    accessor: ProductBookEntity.PRODUCT_URL,
    width: 80,
    // eslint-disable-next-line react/prop-types
    Cell: ({ cell }) =>
      // eslint-disable-next-line react/prop-types
      cell.value && <span style={{ textTransform: "none" }}>{cell.value}</span>,
  },
  {
    Header: TYPE,
    accessor: ProductBookEntity.PRODUCT_TYPE,
    width: 80,
  },
  {
    Header: COMPOSITION,
    accessor: ProductBookEntity.PRODUCT_COMPOSITION,
    width: 80,
  },
  {
    Header: DESCRIPTION,
    accessor: ProductBookEntity.PRODUCT_DESCRIPTION,
    width: 80,
  },
  {
    Header: WEIGHT,
    accessor: ProductBookEntity.UNIT_WEIGHT,
    width: 80,
    Cell: ({ row: { original } }) =>
      roundToDecimal(get(original, ProductBookEntity.UNIT_WEIGHT)),
  },
  {
    Header: CURRENCY,
    accessor: ProductBookEntity.CURRENCY,
    width: 80,
  },
];
